.header{
    width: 100%;
    height: 80vh;
}

.volumeIcons{
    position: absolute;
    top: 1rem;
    left: 1rem;
    
}

.volumeIcons svg{
    font-size: 15px;
    color: silver;
}

.header video{
    object-fit: cover;
    width: 100%;
    height: 80vh;
    outline: none; /* Remove outline around the video */
    border: none;
}

.header::before {
    content: ''; /* Add content to ::before pseudo-element */
    position: absolute;
    left: 0;
    bottom: 2.8rem;
    width: 100%;
    height: 100%;
    background-image: url('../images/mask-group.png');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    z-index: 0;
}

.header__content{
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
}

.header__content h3.date{
    font-size: 24px;
}

.couple_names h3{
    font-size: 40px;
    letter-spacing: 2px;
}

.couple_names img{
    width: 60px;
}

.show-counter{
    margin: 20px 0;
    position: relative;
}

.countdown{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #2b221c;
    font-size: 42px;

}

.countdown span{
    color: #C4A676;
    font-size: 20px;
}

.show-counter #fireworks{
    position: absolute;
    width: 50px;
    mix-blend-mode: multiply;
}