.coupleIntro img{
    width: 80%;
    border-radius: 5px;
    box-shadow: -5px 6px 8px #acaaaa;
}

.coupleIntro p{
    font-size: 20px;
    padding: 15px;
    line-height: 1;
    text-align: center;
}

.coupleIntro h3{
    font-size: 40px;
    letter-spacing: 3px;
    margin-bottom: 10px;
}

.MapConatiner{
    margin: 10px;
}

.MapConatiner h3{
    text-align: center;
    font-size: 30px;
}


.CarouselContainer{
    background-size: cover;
    background-position: center;
    padding: 5rem 0;
    position: relative;
    height: 60vh;
}

.CarouselContainer::before{
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background: #212736;
    top: 0;
    left: 0;
    opacity: 0.91;
    z-index: 2;
    
}

.CarouselContainer > *{
    position: relative;
    z-index: 5;
}

.CarouselContainer img{
    width: 80%;
    border-radius: 5px;
    
    opacity: 1;
    transition: all .3s ease-in-out;
    animation: zoomin .8s ease-in-out;
}

.CarouselContainer h1{
    margin-top: 1rem;
    text-align: center;
    font-size: 18px;
}

.CarouselContainer a{
    color: white;
    font-size: 18px;
    /* text-decoration: none; */
}


@keyframes zoomin {
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}

/* -------------- Gallery -----------------------------------------  */

.gallery__container{
    margin: 20px;
}


.gallery__container h1{
    text-align: center;
}

.videoContainer video{
    object-fit: cover;
    width: 100%;
    height: 80vh;
    outline: none;
    border: none;
}

.videoContainer.horizontal video{
    height: 8.8vh;
}
.videoContainer.vertical video{
    height: 18.5vh;
}

.row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
}
  
  /* Create four equal columns that sits next to each other */
.column {
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
    max-width: 50%;
    padding: 0 4px;
}
  
.column img, .column video {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    box-shadow: -2px 5px 7px #a7a7a7;
}



/* 
@media screen and (max-width: 800px) {
    .column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
} */
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
/* @media screen and (max-width: 600px) {
    .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
} */


/* ---------------- Footer ---------------------------------- */

.footer__container{
    background: #212736;
    padding: 4px;
}

.footer__container p, .footer__container a{
    font-size: 12px;
    color: white;
}

.footer__container svg{
    font-size: 12px;
}

#venueAddress{
    text-align: center;
    margin: 20px 0;
    color: #939985;
    font-size: 18px;
}